import apiRequest from './api-request';

const resource = 'orders';

export function getOrders() {
    return apiRequest({
        method: 'GET',
        resource
    });
}

export function getOrdersByTenantId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `tenants/${id}/${resource}`
    });
}

export function getOrdersByGroupId(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `groups/${id}/${resource}`
    });
}

export function getOrder(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource,
        id
    });
}

export function getOrderMeta(id: number | string) {
    return apiRequest({
        method: 'GET',
        resource: `${resource}/${id}/meta`
    });
}

export function getPublishedAndPublicJobsHtml() {
    return apiRequest({
        method: 'GET',
        resource: 'published-public-jobs/?type=html'
    });
}

export function createOrder(body: any) {
    return apiRequest({
        method: 'POST',
        resource,
        body
    });
}

export function updateOrder(id: number | string, body: any) {
    return apiRequest({
        method: 'PUT',
        resource,
        id,
        body
    });
}

export function deleteOrder(id: number | string) {
    return apiRequest({
        method: 'DELETE',
        resource,
        id
    });
}
