import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Table, CopyHtmlButton } from '../CommonComponents';
import { Container, Paper } from '../StyledComponents';

import { useDispatch, useSelector } from '../../redux/hooks';
import {
    fetchAllOrders,
    selectAllOrders,
    selectFetchingAllOrders
} from '../../redux/actions/orders';
import { getPublishedAndPublicJobsHtml } from '../../api-admin/orders';
import { formatOrderStatus } from '../../utils/parsing';
import { OrderStatusType } from '../../types';

const columns = [{
    name: 'Nummer',
    key: 'id',
    canSearch: true
}, {
    name: 'Företag',
    key: 'tenantName',
    canSearch: true
}, {
    name: 'Upphämtningsdatum',
    key: 'firstPickupDate',
    type: 'date' as 'date',
    canFilter: true
}, {
    name: 'Upphämtningsort',
    key: 'pickupCity',
    canSearch: true
}, {
    name: 'Leveransort',
    key: 'deliveryCity',
    canSearch: true
}, {
    name: 'Totalvikt',
    key: 'grossWeight',
    type: 'kg' as 'kg',
    canFilter: true
}, {
    name: 'Antal anbud',
    key: 'offerCount',
    type: 'number' as 'number',
    canFilter: true
}, {
    name: 'Status',
    key: 'status',
    getValue: ({ status }: { status: OrderStatusType }) => formatOrderStatus(status),
    canSearch: true,
    canFilter: true
}, {
    name: 'Skapades',
    key: 'createdAt',
    type: 'datetime' as 'datetime',
    canFilter: true
}];

export default function Orders() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orders = useSelector(selectAllOrders());
    const isFetching = useSelector(selectFetchingAllOrders());

    React.useEffect(() => {
        dispatch(fetchAllOrders());
    }, [dispatch]);

    const onRowClick = React.useCallback((order: { id: number }) => {
        navigate(`/orders/${order.id}`);
    }, [navigate]);

    return (
        <Container>
            <Header
                title="Uppdrag"
                addUrl="/orders/create"
                CustomComponent={(
                    <CopyHtmlButton
                        getData={getPublishedAndPublicJobsHtml}
                    />
                )}
            />

            <Paper padding={0}>
                <Table
                    name="orders"
                    columns={columns}
                    data={orders}
                    onRowClick={onRowClick}
                    emptyText={isFetching ? 'Hämtar uppdrag...' : 'Det finns inga uppdrag.'}
                    showPagination
                    defaultOrderBy="createdAt"
                    defaultOrderDirection="asc"
                />
            </Paper>
        </Container>
    );
}
