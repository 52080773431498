import toast from './toast';

// eslint-disable-next-line import/prefer-default-export
export async function copyStringToClipboard(getData: () => Promise<any>) {
    try {
        const output = await getData();
        navigator.clipboard.writeText(output);
        toast('Html koperad till urklipp');
    } catch (e) {
        if (e instanceof Error) {
            console.log(`${e.name}: ${e.message}`);
        }
        toast(e, 'Kunde inte kopiera html, försök igen');
    }
}
